import React from "react";
import { Link } from "react-router-dom";
import { useGetRecipeExcerpts } from "../../../recipes/recipes.hook";

export const MostRecentRecipeList: React.FC = () => {
  const {
    data: recipes = [],
    isLoading,
    isIdle,
    error,
  } = useGetRecipeExcerpts();

  if (isLoading || isIdle) return <h1>Loading...</h1>;
  if (error) return <h1>An error has occurred: {error.message}</h1>;

  return (
    <ul>
      {recipes.map((recipe) => (
        <li key={recipe.id}>
          <Link to={`/recipes/${recipe.slug}`}>{recipe.name}</Link>
        </li>
      ))}
    </ul>
  );
};
