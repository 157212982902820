import React from "react";
import { Container } from "inversify";

export const InversifyContext = React.createContext<{
  container: Container | null;
}>({ container: null });

export type ContainerProviderProps = {
  container: Container;
};

export const ContainerProvider: React.FC<ContainerProviderProps> = ({
  container,
  children,
}) => {
  return (
    <InversifyContext.Provider value={{ container }}>
      {children}
    </InversifyContext.Provider>
  );
};
