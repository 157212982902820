import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Recipe, RecipesState } from "../recipes.model";

const initialState: RecipesState = {
  fetching: false,
  items: [],
  selectedItemId: undefined,
};

const recipesSlice = createSlice({
  name: "recipes",
  initialState,
  reducers: {
    fetchRecipes(state) {
      state.fetching = true;
    },
    fetchRecipesSuccess(state, action) {
      state.fetching = false;
      state.items = action.payload.reduce((acc: any[], item: Recipe) => {
        const index = acc.findIndex((i: any) => i.id === item.id);
        if (index <= -1) {
          return [...acc, item];
        }
        acc[index] = item;
        return acc;
      }, state.items);
    },
    fetchRecipesError(state) {
      state.fetching = false;
    },
    fetchRecipeBySlug(state, _action: PayloadAction<string>) {
      state.fetching = true;
    },
    fetchRecipeBySlugSuccess(state, action) {
      state.fetching = false;
      const index = state.items.findIndex(
        (i: any) => i.id === action.payload.id
      );
      if (index > -1) {
        state.items[index] = action.payload;
      } else {
        state.items = [...state.items, action.payload];
      }
    },
    fetchRecipeBySlugError(state) {
      state.fetching = false;
    },
    setRecipes(state, action) {
      state.items = action.payload.reduce((acc: any[], item: Recipe) => {
        const index = acc.findIndex((i: any) => i.id === item.id);
        if (index <= -1) {
          return [...acc, item];
        }
        acc[index] = item;
        return acc;
      }, state.items);
    },
    selectRecipe(state, action) {
      state.selectedItemId = action.payload;
    },
  },
});

export const recipesActions = recipesSlice.actions;
export const recipesReducer = recipesSlice.reducer;
