import React from "react";
import marked from "marked";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import { Header } from "../../common/components/header/Header";
import { Footer } from "../../common/components/footer/Footer";
import { useGetRecipeBySlug } from "../recipes.hook";
import styles from "./Recipe.module.scss";
import { Hero } from "../../common/components/hero/Hero";

export const Recipe: React.FC = () => {
  const { slug } = useParams<any>();
  const { data: recipe, isLoading, isIdle, error } = useGetRecipeBySlug(slug);
  const coverImageUrl = recipe?.coverImage?.url || ``;

  if (isLoading || isIdle) return <h1>Loading...</h1>;
  if (error) return <h1>An error has occurred: {error.message}</h1>;

  return (
    <>
      <Header />
      {coverImageUrl && <Hero src={coverImageUrl} />}
      <section className={`container ${styles.container}`}>
        <section className={styles.hero}>
          <h1 className={`h h--xl ${styles.h}`}>{recipe?.name}</h1>
          <div>{parse(marked(recipe?.description || ""))}</div>
        </section>
        <section className={styles.content}>
          <aside className={styles.ingredientSection}>
            <h3 className="h">Ingredients</h3>
            <ul>
              {recipe?.ingredients.map((ingredient) => (
                <li key={ingredient.name}>
                  {ingredient.quantity} {ingredient.name}
                </li>
              ))}
            </ul>
          </aside>
          <div className={styles.stepSection}>
            <h3 className="h">Steps</h3>
            <div>
              {recipe?.steps.map((step) => (
                <div key={step.name}>
                  <h3 className="h h--xs h--alt">{step.name}</h3>
                  <div>{parse(marked(step?.content || ""))}</div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </section>
      <Footer />
    </>
  );
};
