import { Epic } from "redux-observable";
import { filter, map, switchMap } from "rxjs/operators";
import { container } from "../../../ioc/container";
import { RecipesService, RecipesServiceProvider } from "../recipes.service";
import { recipesActions } from "./recipes.slice";

const recipesService = container.get<RecipesService>(
  RecipesServiceProvider.provide
);

export const fetchRecipesEpic: Epic = (action$) =>
  action$.pipe(
    filter((action) => action.type === recipesActions.fetchRecipes.type),
    switchMap(() => recipesService.getRecipes()),
    map((recipes) => recipesActions.fetchRecipesSuccess(recipes))
  );

export const fetchRecipeBySlugEpic: Epic = (action$) =>
  action$.pipe(
    filter((action) => action.type === recipesActions.fetchRecipeBySlug.type),
    switchMap((action) => recipesService.getRecipeBySlug(action.payload)),
    map((recipes) => recipesActions.fetchRecipeBySlugSuccess(recipes))
  );
