import React, { ReactElement } from "react";
import { v4 as uuid } from "uuid";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { routes } from "./routes";

export function RouterOutlet(): ReactElement {
  const routesWithId = routes.map((r) => ({
    ...r,
    id: uuid(),
  }));

  return (
    <Router>
      <Switch>
        {routesWithId.map((route) => (
          <Route exact={route.exact} path={route.path} key={route.id}>
            <route.Component />
          </Route>
        ))}
      </Switch>
    </Router>
  );
}
