import React from "react";
import styles from "./About.module.scss";

export const About: React.FC = () => {
  return (
    <div className={styles.page}>
      <h1>About</h1>
    </div>
  );
};
