import "reflect-metadata";
import React from "react";
import ReactDOM from "react-dom";
import "./scss/index.scss";

import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { App } from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { ContainerProvider } from "./ioc/Provider";
import { container } from "./ioc/container";
import { store } from "./store";

const queryClient = new QueryClient();

ReactDOM.render(
  <ContainerProvider container={container}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Provider>
    </QueryClientProvider>
  </ContainerProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
