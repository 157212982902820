import React from "react";
import { Link } from "react-router-dom";
import { HamburgerButton } from "../hamburger-button/HamburgerButton";
import styles from "./Header.module.scss";

export const Header: React.FC = () => {
  return (
    <header className={styles.wrap}>
      <div className={styles.container}>
        <figure className={styles.logoContainer}>
          <Link className="h h--l" to="/">
            Verse
          </Link>
        </figure>
        <div className={styles.menuButtonContainer}>
          <HamburgerButton />
        </div>
      </div>
    </header>
  );
};
