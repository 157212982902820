import { gql } from "@apollo/client";
import { inject, injectable } from "inversify";
import {
  GraphqlService,
  GraphqlServiceProvider,
} from "../common/services/graphql.service";
import { Recipe, RecipeExcerpt } from "./recipes.model";

@injectable()
export class RecipesService {
  @inject(GraphqlServiceProvider.provide)
  private graphqlService: GraphqlService;

  public async getRecipes(): Promise<RecipeExcerpt[]> {
    const { data } = await this.graphqlService.query<{
      recipes: RecipeExcerpt[];
    }>({
      query: gql`
        query {
          recipes {
            id
            slug
            name: title
            difficulty
            preparationDuration
            cookingDuration
            description
          }
        }
      `,
    });

    return data.recipes;
  }

  public async getRecipeBySlug(slug: string): Promise<Recipe | undefined> {
    const { data } = await this.graphqlService.query<{ recipes: Recipe[] }>({
      query: gql`
        query {
          recipes(where: { slug: "${slug}" }) {
            id
            slug
            name:title
            coverImage {
              url
            }
            difficulty
            preparationDuration
            cookingDuration
            description
            steps {
              name
              content
            }
            ingredients: ingredientItems {
              name: alternativeName
              quantity
              ingredient {
                name
                minPrice
                maxPrice
              }
            }
          }
        }
      `,
    });

    return data.recipes.length > 0 ? data.recipes[0] : undefined;
  }
}

export const RecipesServiceProvider = {
  provide: "RecipesService",
  useClass: RecipesService,
};
