import {
  ApolloClient,
  ApolloQueryResult,
  FetchResult,
  InMemoryCache,
  MutationOptions,
  NormalizedCacheObject,
  OperationVariables,
  QueryOptions,
} from "@apollo/client";
import { injectable } from "inversify";
import config from "../../../config";

@injectable()
export class GraphqlService {
  public apolloClient: ApolloClient<NormalizedCacheObject>;

  constructor() {
    this.apolloClient = new ApolloClient({
      uri: config.STRAPI_GRAPHQL_URL,
      cache: new InMemoryCache(),
    });
  }

  public get client(): ApolloClient<NormalizedCacheObject> {
    return this.apolloClient;
  }

  public async query<T = any, TVariables = OperationVariables>(
    options: QueryOptions<TVariables, T>
  ): Promise<ApolloQueryResult<T>> {
    return this.client.query(options);
  }

  public async mutate<T = any, TVariables = OperationVariables>(
    options: MutationOptions<T, TVariables>
  ): Promise<FetchResult<T>> {
    return this.client.mutate(options);
  }
}

export const GraphqlServiceProvider = {
  provide: "GraphqlService",
  useClass: GraphqlService,
};
