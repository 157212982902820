import React from "react";
import { Footer } from "../../components/footer/Footer";
import { Header } from "../../components/header/Header";
import { Hero } from "../../components/hero/Hero";
import { MostRecentRecipeList } from "../../components/most-recent-recipe-list/MostRecentRecipeList";
import styles from "./Home.module.scss";
import heroImage from "../../../../assets/hero.jpg";

export const Home: React.FC = () => {
  return (
    <main className={styles.page}>
      <Header />
      <Hero src={heroImage} />
      <div className={styles.content}>
        <div className="container">
          <h1 className="h h--xxxl">Verse</h1>
          <MostRecentRecipeList />
        </div>
      </div>
      <Footer />
    </main>
  );
};
