import { Container } from "inversify";
import {
  GraphqlService,
  GraphqlServiceProvider,
} from "../app/common/services/graphql.service";
import {
  RecipesService,
  RecipesServiceProvider,
} from "../app/recipes/recipes.service";

export const container = new Container();
container
  .bind<GraphqlService>(GraphqlServiceProvider.provide)
  .to(GraphqlServiceProvider.useClass);
container
  .bind<RecipesService>(RecipesServiceProvider.provide)
  .to(RecipesServiceProvider.useClass);
