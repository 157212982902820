import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import { RecipesState } from "../app/recipes/recipes.model";
import {
  fetchRecipeBySlugEpic,
  fetchRecipesEpic,
} from "../app/recipes/store/recipes.epic";
import { recipesReducer } from "../app/recipes/store/recipes.slice";

export interface RootState {
  recipes: RecipesState;
}

const epicMiddleware = createEpicMiddleware();

export const store = configureStore({
  reducer: combineReducers({
    recipes: recipesReducer,
  }),
  middleware: [epicMiddleware],
});

epicMiddleware.run(combineEpics(fetchRecipesEpic, fetchRecipeBySlugEpic));
