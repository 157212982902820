const lookupEnvVar = (name: string): string | undefined => {
  if (typeof (window as any).FAB_SETTINGS === "object") {
    return (window as any).FAB_SETTINGS[name];
  }

  return process.env[`REACT_APP_${name}`];
};

const config = {
  STRAPI_BASE_URL: lookupEnvVar("STRAPI_BASE_URL"),
  STRAPI_GRAPHQL_URL: lookupEnvVar("STRAPI_GRAPHQL_URL"),
};

export default config;
