import { QueryObserverResult, useQuery } from "react-query";
import { useInjection } from "../../ioc/hook";
import { Recipe, RecipeExcerpt } from "./recipes.model";
import { RecipesService, RecipesServiceProvider } from "./recipes.service";

export function useGetRecipeExcerpts(): QueryObserverResult<
  RecipeExcerpt[],
  Error
> {
  const recipesService = useInjection<RecipesService>(
    RecipesServiceProvider.provide
  );
  return useQuery(`getRecipeExcerpts`, () => recipesService.getRecipes(), {
    initialData: [],
  });
}

export function useGetRecipeBySlug(
  slug: string
): QueryObserverResult<Recipe | undefined, Error> {
  const recipesService = useInjection<RecipesService>(
    RecipesServiceProvider.provide
  );
  return useQuery(
    ["getRecipe", slug],
    () => recipesService.getRecipeBySlug(slug),
    { initialData: undefined }
  );
}
