import React from "react";
import styles from "./HamburgerButton.module.scss";

export const HamburgerButton: React.FC = () => {
  return (
    <button type="button" className={styles.button}>
      <span />
    </button>
  );
};
