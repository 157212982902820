import { RouteObject } from "./router.model";

import { Home } from "../app/common/pages/home/Home";
import { About } from "../app/common/pages/about/About";
import { NotFound } from "../app/common/pages/404/NotFound";
import { Recipe } from "../app/recipes/pages/Recipe";

export const routes: RouteObject[] = [
  {
    path: "/",
    exact: true,
    Component: Home,
  },
  {
    path: "/about",
    exact: true,
    Component: About,
  },
  {
    path: "/recipes/:slug",
    exact: true,
    Component: Recipe,
  },
  {
    path: "*",
    Component: NotFound,
  },
];
