import React from "react";
import styles from "./Hero.module.scss";

export interface HeroProps {
  src: string;
}

export const Hero: React.FC<HeroProps> = ({ src, children }) => {
  return (
    <section className={styles.hero} style={{ backgroundImage: `url(${src}` }}>
      {children}
    </section>
  );
};
